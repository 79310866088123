<template>
  <div style="width: 100%">
    <div style="width: 100%;height: 160px">
      <div style="width: 100%;height: 100px">
        <div style="height: 100%;width: 70%;float: left">
          <img src="https://dsjyjy.obs.cn-north-4.myhuaweicloud.com/logo_u.png" fit="cover" style="height: 100%;margin-left: 50px" />
        </div>
        <div style="height: 100%;width: 30%;float: left;display: flex;justify-content: center;align-items: center;">
          <el-row style="width: 100%;">
            <el-col :span="18">
              <el-input placeholder="请输入内容" v-model="searchParam" @keyup.enter.native="searchAll" >

              </el-input>
            </el-col>
            <el-col :span="6">
              <el-button  type="primary" @click="searchAll">搜索</el-button>
            </el-col>
          </el-row>
        </div>

      </div>
      <div style="width: 100%;height: 40px">
        <el-menu :default-active="$route.path.replace('/','')"
                 mode="horizontal" background-color="#0664de" router text-color="#FFFFFF" active-text-color="#dbba12">
          <el-menu-item index="index"><span class="headerTitle">主页</span></el-menu-item>
          <el-menu-item index="gaikuang"><span class="headerTitle">研究院概况</span></el-menu-item>

          <el-submenu index="news">
            <template slot="title"><span class="headerTitle"
                                         @click="$router.push({path: 'yuannei', query: {type: '1' }})">新闻动态</span>
            </template>
            <el-menu-item index="yuannei" @click="$router.push({path: 'yuannei', query: {type: '1' }})">院内资讯
            </el-menu-item>
            <el-menu-item index="hangye" @click="$router.push({path: 'hangye', query: {type: '2' }})">行业动态
            </el-menu-item>
            <el-menu-item index="xueshu" @click="$router.push({path: 'xueshu', query: {type: '3' }})">学术动态
            </el-menu-item>
            <el-menu-item index="zhengce" @click="$router.push({path: 'zhengce', query: {type: '4' }})">政策法规
            </el-menu-item>
          </el-submenu>

          <el-submenu index="notice">
            <template slot="title"><span class="headerTitle"
                                         @click="$router.push({path: 'yuanneinotice', query: {type: '5' }})">通知公告</span>
            </template>
            <el-menu-item index="yuanneinotice" @click="$router.push({path: 'yuanneinotice', query: {type: '5' }})">
              院内公告
            </el-menu-item>
            <el-menu-item index="huiyinotice" @click="$router.push({path: 'huiyinotice', query: {type: '14' }})">
              会议公告
            </el-menu-item>
          </el-submenu>

          <el-menu-item index="teamView"><span class="headerTitle">专家团队</span></el-menu-item>
          <el-submenu index="5">
            <template slot="title"><span class="headerTitle" @click="$router.push({path: 'projects'})">科研成果</span>
            </template>
            <el-menu-item index="projects">承担项目</el-menu-item>
            <el-menu-item index="copyright">发表著作</el-menu-item>
            <el-menu-item index="award">获得奖励</el-menu-item>
            <el-menu-item index="learn">学习专栏</el-menu-item>

          </el-submenu>
          <el-menu-item index="digitalYantai"><span class="headerTitle">数说烟台</span></el-menu-item>
          <!--            <el-menu-item index="cooperation"><span class="headerTitle">合作交流</span></el-menu-item>-->
          <el-menu-item index="newsletter"><span class="headerTitle">实时分析</span></el-menu-item>
          <el-menu-item index="job"><span class="headerTitle">加入我们</span></el-menu-item>
          <!--            <el-submenu index="7">-->
          <!--              <template slot="title"><span class="headerTitle" @click="$router.push({path: 'job'})">加入我们</span></template>-->
          <!--              <el-menu-item index="job">人才招聘</el-menu-item>-->
          <!--              <el-menu-item index="salaryPackage">薪资制度</el-menu-item>-->
          <!--              <el-menu-item index="trainingAndDevelopment">培训发展</el-menu-item>-->
          <!--              <el-menu-item index="careerPlanning">职业规划</el-menu-item>-->
          <!--              <el-menu-item index="teamBuilding">团队建设</el-menu-item>-->
          <!--            </el-submenu>-->

        </el-menu>
      </div>
    </div>



    <div class="mainss">
      <router-view></router-view>
    </div>
    <div class="footer">
      <div style="width: 100%;height: 50px;"></div>
      <div style="width: 100%;height: 300px;">
        <el-row>
          <el-col :span="12" style="text-align: left">
            <el-row>
              <el-col :span="6">
                <div style="color: #FFFFFF;font-size: 20px">
                  研究院概况
                </div>
                <a @click="$router.push('/institutesInfo')">
                  <div style="margin-top: 20px;color: #CCCCCC;font-size: 16px;">
                    研究院简介
                  </div>
                </a>
                <a @click="$router.push('/honor')">
                  <div style="margin-top: 5px;color: #CCCCCC;font-size: 16px;">
                    荣誉资质
                  </div>
                </a>
                <a @click="$router.push('/leaderShip')">
                  <div style="margin-top: 5px;color: #CCCCCC;font-size: 16px;">
                    研究院领导
                  </div>
                </a>
                <!--                 <div style="margin-top: 5px;color: #CCCCCC;font-size: 16px;"  >-->
                <!--                   项目分布-->
                <!--                 </div>-->
                <a @click="$router.push('/organizationChart')">
                  <div style="margin-top: 5px;color: #CCCCCC;font-size: 16px;">
                    组织架构
                  </div>
                </a>
                <a @click="$router.push('/wenhua')">
                  <div style="margin-top: 5px;color: #CCCCCC;font-size: 16px;">
                    研究院文化
                  </div>
                </a>
              </el-col>
              <el-col :span="6">
                <div style="color: #FFFFFF;font-size: 20px">
                  新闻动态
                </div>
                <a @click="$router.push({path: 'yuannei', query: {type: '1' }})">
                  <div style="margin-top: 20px;color: #CCCCCC;font-size: 16px;">
                    院内资讯
                  </div>
                </a>
                <a @click="$router.push({path: 'hangye', query: {type: '2' }})">
                  <div style="margin-top: 5px;color: #CCCCCC;font-size: 16px;">
                    行业动态
                  </div>
                </a>

                <a @click="$router.push({path: 'xueshu', query: {type: '3' }})">
                  <div style="margin-top: 5px;color: #CCCCCC;font-size: 16px;">
                    学术动态
                  </div>
                </a>
                <a @click="$router.push({path: 'zhengce', query: {type: '4' }})">
                  <div style="margin-top: 5px;color: #CCCCCC;font-size: 16px;">
                    政策法规
                  </div>
                </a>

              </el-col>
              <el-col :span="6">
                <div style="color: #FFFFFF;font-size: 20px">
                  研究成果
                </div>
                <a @click="$router.push('/projects')">
                  <div style="margin-top: 20px;color: #CCCCCC;font-size: 16px;">
                    承担项目
                  </div>
                </a>
                <a @click="$router.push('/copyright')">
                  <div style="margin-top: 5px;color: #CCCCCC;font-size: 16px;">
                    发表著作
                  </div>
                </a>
                <a @click="$router.push('/award')">
                  <div style="margin-top: 5px;color: #CCCCCC;font-size: 16px;">
                    获得奖励
                  </div>
                </a>

              </el-col>
              <el-col :span="6">
                <div style="color: #FFFFFF;font-size: 20px">
                  友情链接
                </div>
                <a href="http://bdb.shandong.gov.cn/" target="_blank">
                  <div style="margin-top: 20px;color: #CCCCCC;font-size: 16px;">
                    山东大数据局
                  </div>
                </a>

                <a href="https://dsjj.yantai.gov.cn/" target="_blank">
                  <div style="margin-top: 5px;color: #CCCCCC;font-size: 16px;">
                    烟台大数据局
                  </div>
                </a>
                <a href="https://www.ytu.edu.cn/index.htm" target="_blank">
                  <div style="margin-top: 5px;color: #CCCCCC;font-size: 16px;">
                    烟台大学
                  </div>
                </a>
                <a href="https://www.ldu.edu.cn/" target="_blank">
                  <div style="margin-top: 5px;color: #CCCCCC;font-size: 16px;">
                    鲁东大学
                  </div>
                </a>
                <a href="https://www.sdtbu.edu.cn/" target="_blank">
                  <div style="margin-top: 5px;color: #CCCCCC;font-size: 16px;">
                    山东工商学院
                  </div>
                </a>

              </el-col>
            </el-row>
          </el-col>
          <el-col :span="5" style="text-align: left">
            <div style="color: #FFFFFF;font-size: 20px;width: 100%">
              关于我们
            </div>
            <div style="display:block;width: 100%;height:130px;">
              <div style="float: left;margin-right: 20px;margin-top: 20px">
                <el-image src="/gzh.bmp" fit="cover" style="width: 100%"></el-image>

              </div>

            </div>
            <div style="width: 100%;text-align: center">
              <div style="color: #CCCCCC">公众号</div>
            </div>
          </el-col>
          <el-col :span="7" style="text-align: left">
            <div style="width: 100%;margin-top: 50px;color: #CCCCCC">
              <p>电话：0535-8209887</p><p>邮箱：DSJYJY0808@163.com</p>
              <p><a @click="toAddress" style="color: #CCCCCC;">地址：烟台高新区蓝海路2号1号办公楼1601号<i class="el-icon-location-outline"></i></a></p>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="friendship">

        Copyright © 2023 烟台智慧城市大数据局研究院 <a href="https://beian.miit.gov.cn" target="_blank" style="color: #CCCCCC;">鲁ICP备2023026527号</a>
      </div>
    </div>
    <el-dialog
        title="搜索结果"
        :visible.sync="searchResultState"
        destroy-on-close
        :modal-append-to-body="false"
        append-to-body
        width="80%"
    >
      <div style="width: 100%;height: 50px;display: flex;justify-content: center;align-items: center">

        <el-col :span="12">
          <el-input placeholder="请输入内容" v-model="searchParam">
            <el-button slot="append" icon="el-icon-search" @click="searchAll"></el-button>
          </el-input>
        </el-col>

      </div>
      <div style="width: 100%;height: 50px"></div>
      <div class="newsList">
        <a v-for="(item,index) in datas" :key="index" @click="selectArticle(item)">
          <div class="newsRow">
            <div class="newsTitle">
              <span
                  style="margin-top: 12px;width:100%;overflow: hidden;margin-left:15px;font-size: 16px">{{ item.title }}</span>
            </div>
            <div class="newsData">
              <span
                  style="margin-top: 12px;width:100%;font-size: 16px;text-align: end;padding-right: 15px">{{ item.articleDate }}</span>
            </div>
          </div>
        </a>
        <div style="width: 100%;text-align: center;margin-top: 20px">
          <el-pagination
              @current-change="handleCurrentChange"
              background
              layout="total,prev, pager, next"
              :total="page.total">
          </el-pagination>
        </div>
      </div>
    </el-dialog>
    <el-dialog
        :title="newsDataDetail.title"
        :visible.sync="newsLoadState"
        destroy-on-close
        :modal-append-to-body="false"
        append-to-body
        width="80%"
    >
      <div style="width: 95%;margin-left: 2%">
        <avue-article :props="props" :data="newsDataDetail"></avue-article>
      </div>

    </el-dialog>
    <el-dialog
        :title="text.formattedAddress"
        :visible.sync="addressState"
        destroy-on-close
        :modal-append-to-body="false"
        append-to-body
        width="50%"
    >
      <div class="location-box">
        <template>
          <!-- :scroll-wheel-zoom="true":开启滚轮缩放,center:"{lng: 116.404, lat: 39.915}",格式如下,定义地图经纬度 -->
          <baidu-map style="height: 600px" class="map" :zoom="18" :center="text" :scroll-wheel-zoom="true">
            <!-- BMAP_NORMAL_MAP:展示图片街道视图, BMAP_HYBRID_MAP:卫星和路网的混合视图-->
            <bm-map-type :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']"></bm-map-type>
            <!-- BMAP_ANIMATION_BOUNCE:定位点跳动 -->
            <bm-marker :position="text" :dragging="true" animation="BMAP_ANIMATION_BOUNCE">

            </bm-marker>
          </baidu-map>
        </template>
      </div>
    </el-dialog>
  </div>

</template>

<script>

import {searchArticlePage} from "@/api/news";
export default {
  name: 'HomeView',
  components: {},
  props: {},
  data() {
    return {
      form: '',
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      text:{
        formattedAddress: "烟台高新区蓝海路2号1号楼办公1601号",
        lng: 121.488078,
        lat: 37.459944
      },
      props: {
        title: 'title',
        meta: 'articleTime',
        lead: 'articleLead',
        body: 'body'
      },
      newsDataDetail: {},
      newsLoadState: false,
      activeIndex: 'index',
      searchParam: null,
      datas: [],
      searchResultState: false,
      addressState: false,
    }
  },

  computed: {
    dateNow() {

      const date = new Date() // 获取时间
      const year = date.getFullYear() // 获取年
      const month = date.getMonth() + 1 // 获取月
      const strDate = date.getDate() // 获取日
      // var day = date.getDate() //
      const day = '日一二三四五六'.charAt(new Date().getDay()) // 周一返回的是1，周六是6，但是周日是0
      const x = '今天是' + year + '年' + month + '月' + strDate + '日 星期' + day;
      return x
    }
  },
  watch: {},
  methods:{
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    searchAll() {
      console.log('searchAll:', this.searchParam)
      if (this.searchParam && this.searchParam !== '') {
        searchArticlePage({pageSize: 10, scs: 'sort(desc)', title: this.searchParam}).then(res => {
          const data = res.data.data;
          this.datas = data.records;
          this.searchResultState = true
        })
      }
    },
    handleCurrentChange(v) {
      this.page.currentPage = v
      this.onLoad()
    },
    selectArticle(v) {
      this.newsDataDetail = v
      this.newsLoadState = true
    },
    onLoad() {
      const param = {
        pageSize: this.page.pageSize,
        currentPage: this.page.currentPage,
        title: this.searchParam,
        scs: 'sort(desc)'
      }

      searchArticlePage(param).then(res => {
        const data = res.data.data;
        this.page.total = Number(data.total);
        this.datas = data.records;
      })
    },
    toAddress(){
      this.addressState = true
    }
  }
}
</script>
